// JavaScript Document
let $form, $message, timeoutId;

function captchaSubmit(grecaptchaResponse) {
  // Considering jQuery.validate is being used as the validation mechanism
  if ($form.valid()) {
    $form.find(".g-recaptcha-response").length && $form.find(".g-recaptcha-response").remove();
    $.ajax({
      type: "POST",
      url: "form/send-mail.php",
      data: $form.serialize() + "&g-recaptcha-response=" + grecaptchaResponse
    })
    .done(function(response) {
        $form[0].submit('captchaSubmit');
        // Resetting reCaptcha (v2) if available
        grecaptcha && grecaptcha.reset();
        // Reset the form input fields
        $form.trigger("reset");
    })
    .fail(function(error) {
        // Display error message to the user | add error class to the message wrapper
        console.log(error);
        $message.slideUp(400, () => {
          $message
            .removeClass("success")
            .addClass("error")
            .text(response ? response : "Oops! An error occured")
            .slideDown();
        });
      });
  } else {
    grecaptcha && grecaptcha.reset();
  }
}

$('.play-button').on('click', function() {
    $('#HomeVideoPlaceholderImage').hide();
    $('#HomeVideoWrapper').delay(500).fadeIn();
    $('#HomeVideoWrapper #video')[0].play();
});
if (!(window.location.href.indexOf("merci") > -1)) {
    document.getElementById('video').addEventListener('ended', videoHandler, false);

    function videoHandler(e) {
        // What you want to do after the event
        $('#HomeVideoWrapper').hide();
        $('#HomeVideoPlaceholderImage').fadeIn();
    }
}

$(document).ready(function() {

    var frmId;

    function removePin() {
        if ($('#ontario div').hasClass('pinYellow') || $('#quebec div').hasClass('pinYellow') || $('#britishcolumbia div').hasClass('pinYellow') || $('#usa div').hasClass('pinYellow')) {
            $('#ontario div, #quebec div,#britishcolumbia div , #usa div ').removeClass('pinYellow')
            $('#ontario h3, #quebec h3,#britishcolumbia h3 , #usa h3 ').removeClass('hWhite')
            $('#ontario div, #quebec div,#britishcolumbia div , #usa div ').addClass('pin')
        }
    }
    $(document).scroll(function() {
        var $nav = $(".fixed-top");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
        // $('.navbar-light .navbar-nav .nav-link').toggleClass('yellowToggle');
    });

    $(".carousel-control-prev").click(function() {
        $("#carouselExampleIndicators").carousel("prev");
    });
    $(".carousel-control-next").click(function() {
        $("#carouselExampleIndicators").carousel("next");
    });


    $('#ontario').on('click', function() {
        $('.locationsQuebec, .locationsBritishcolumbia, .locationsUsa ').hide('slow');
        removePin();
        if ($('.locationsOntario').css('display') == 'none') {
            $('.locationsOntario').show('slow');
            $('#ontario div').addClass('pinYellow')
            $('#ontario div').removeClass('pin');
            $('#ontario h3').addClass('hWhite')
            $('.threeTrucks').addClass('top');
        } else {
            $('.locationsOntario').hide('slow');
            $('#ontario div').addClass('pin')
            $('#ontario div').removeClass('pinYellow');
            $('#ontario h3').removeClass('hWhite')
            $('.threeTrucks').removeClass('top')
        }
    });

    $('#quebec').on('click', function() {
        $('.locationsOntario, .locationsBritishcolumbia, .locationsUsa ').hide('slow');
        removePin();
        if ($('.locationsQuebec').css('display') == 'none') {
            $('.locationsQuebec').show('slow');
            $('#quebec div').addClass('pinYellow')
            $('#quebec div').removeClass('pin');
            $('#quebec h3').addClass('hWhite')
            $('.threeTrucks').addClass('top');
        } else {
            $('.locationsQuebec').hide('slow');
            $('#quebec div').addClass('pin')
            $('#quebec div').removeClass('pinYellow');
            $('#quebec h3').removeClass('hWhite')
            $('.threeTrucks').removeClass('top')
        }
    });

    $('#britishcolumbia').on('click', function() {
        $('.locationsQuebec, .locationsOntario, .locationsUsa ').hide('slow');
        removePin();
        if ($('.locationsBritishcolumbia').css('display') == 'none') {
            $('.locationsBritishcolumbia').show('slow');
            $('#britishcolumbia div').addClass('pinYellow')
            $('#britishcolumbia div').removeClass('pin');
            $('#britishcolumbia h3').addClass('hWhite')
            $('.threeTrucks').addClass('top');
        } else {
            $('.locationsBritishcolumbia').hide('slow');
            $('#britishcolumbia div').addClass('pin')
            $('#britishcolumbia div').removeClass('pinYellow');
            $('#britishcolumbia h3').removeClass('hWhite')
            $('.threeTrucks').removeClass('top')
        }
    });
    $('#usa').on('click', function() {
        $('.locationsQuebec, .locationsBritishcolumbia, .locationsOntario').hide('slow');
        removePin();
        if ($('.locationsUsa').css('display') == 'none') {
            $('.locationsUsa').show('slow');
            $('#usa div').addClass('pinYellow')
            $('#usa div').removeClass('pin');
            $('#usa h3').addClass('hWhite')
            $('.threeTrucks').addClass('top');
        } else {
            $('.locationsUsa').hide('slow');
            $('#usa div').addClass('pin')
            $('#usa div').removeClass('pinYellow');
            $('#usa h3').removeClass('hWhite')
            $('.threeTrucks').removeClass('top')
        }
    });
    //Mobile
    window.addEventListener("resize", myFunction);

    function myFunction() {
        var width = window.innerWidth;
        if ($(window).width() < 992) {
            // debugger;
            $('.locationsOntario, .locationsBritishcolumbia, .locationsUsa, .locationsQuebec').css("display", "none");
        }
    };

    $('.locationsMobile .locationsMobile1 #ontario').on('click', function() {
        $('.locationsQuebecMobile, .locationsBritishcolumbiaMobile, .locationsUsaMobile').hide('slow');
        removePin();
        // $('.locationsOntarioMobile').toggle('slow');
        // if ($('.threeTrucks').hasClass('top')) {
        //     $('.threeTrucks').removeClass('top')
        // } else {
        //     $('.threeTrucks').addClass('top')
        // }
        if ($('.locationsOntarioMobile').css('display') == 'none') {
            $('.locationsOntarioMobile').show('slow');
            $('#ontario div').addClass('pinYellow')
            $('#ontario div').removeClass('pin');
            $('#ontario h3').addClass('hWhite')
            $('.threeTrucks').addClass('top');
        } else {
            $('.locationsOntarioMobile').hide('slow');
            $('#ontario div').addClass('pin')
            $('#ontario div').removeClass('pinYellow');
            $('#ontario h3').removeClass('hWhite')
            $('.threeTrucks').removeClass('top')
        }
    });

    $('.locationsMobile .locationsMobile1 #quebec').on('click', function() {
        $('.locationsOntarioMobile, .locationsBritishcolumbiaMobile, .locationsUsaMobile').hide('slow');
        removePin();
        if ($('.locationsQuebecMobile').css('display') == 'none') {
            $('.locationsQuebecMobile').show('slow');
            $('#quebec div').addClass('pinYellow')
            $('#quebec div').removeClass('pin');
            $('#quebec h3').addClass('hWhite')
            $('.threeTrucks').addClass('top');
        } else {
            $('.locationsQuebecMobile').hide('slow');
            $('#quebec div').addClass('pin')
            $('#quebec div').removeClass('pinYellow');
            $('#quebec h3').removeClass('hWhite')
            $('.threeTrucks').removeClass('top')
        }
    });

    $('.locationsMobile .locationsMobile2 #britishcolumbia').on('click', function() {
        $('.threeTrucks').removeClass('top')
        $('.locationsQuebecMobile, .locationsOntarioMobile, .locationsUsaMobile').hide('slow');
        removePin();

        if ($('.locationsBritishcolumbiaMobile').css('display') == 'none') {
            $('.locationsBritishcolumbiaMobile').show('slow');
            $('#britishcolumbia div').addClass('pinYellow')
            $('#britishcolumbia div').removeClass('pin');
            $('#britishcolumbia h3').addClass('hWhite')
            $('.threeTrucks').addClass('top');
        } else {
            $('.locationsBritishcolumbiaMobile').hide('slow');
            $('#britishcolumbia div').addClass('pin')
            $('#britishcolumbia div').removeClass('pinYellow');
            $('#britishcolumbia h3').removeClass('hWhite')
            $('.threeTrucks').removeClass('top')
        }
    });

    $('.locationsMobile .locationsMobile2 #usa').on('click', function() {
        $('.threeTrucks').removeClass('top');
        $('.locationsQuebecMobile, .locationsBritishcolumbiaMobile, .locationsOntarioMobile').hide('slow');
        removePin();
        if ($('.locationsUsaMobile').css('display') == 'none') {
            $('.locationsUsaMobile').show('slow');
            $('#usa div').addClass('pinYellow')
            $('#usa div').removeClass('pin');
            $('#usa h3').addClass('hWhite')
            $('.threeTrucks').addClass('top');
        } else {
            $('.locationsUsaMobile').hide('slow');
            $('#usa div').addClass('pin')
            $('#usa div').removeClass('pinYellow');
            $('#usa h3').removeClass('hWhite')
            $('.threeTrucks').removeClass('top')
        }
    });

    // $("a").on('click', function(event) {

    //   if (this.hash !== "") {

    //     event.preventDefault();

    //     var hash = this.hash;

    //     // $('html, body').animate({
    //     //   scrollTop: $(hash).offset().top
    //     // }, 800, function(){

    //       window.location.hash = hash;
    //       debugger;
    //     // });
    //   }
    // });

    //Form Online Driver
    // $Id: $
    function zf_ValidateAndSubmit() {

        if (zf_CheckMandatory(frmId)) {

            if (zf_ValidCheck(frmId)) {

                if (isSalesIQIntegrationEnabled) {

                    zf_addDataToSalesIQ();
                }
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    function zf_CheckMandatory(formId) {
        for (var i = 0; i < zf_MandArray.length; i++) {
            var fieldObj = document.getElementById(formId)[zf_MandArray[i]];
            if (fieldObj) {
                if (fieldObj.nodeName != null) {
                    if (fieldObj.nodeName == 'OBJECT') {
                        if (!zf_MandatoryCheckSignature(fieldObj)) {
                            zf_ShowErrorMsg(zf_MandArray[i]);
                            debugger;
                            return false;
                        }
                    } else if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length == 0) {
                        if (fieldObj.type == 'file') {
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_MandArray[i]);
                            return false;
                        }
                        fieldObj.focus();
                        zf_ShowErrorMsg(zf_MandArray[i]);
                        return false;
                    } else if (fieldObj.nodeName == 'SELECT') { // No I18N
                        if (fieldObj.options[fieldObj.selectedIndex].value == '-Select-') {
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_MandArray[i]);
                            return false;
                        }
                    } else if (fieldObj.type == 'checkbox' || fieldObj.type == 'radio') {
                        if (fieldObj.checked == false) {
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_MandArray[i]);
                            return false;
                        }
                    }
                } else {
                    var checkedValsCount = 0;
                    var inpChoiceElems = fieldObj;
                    for (var ii = 0; ii < inpChoiceElems.length; ii++) {
                        if (inpChoiceElems[ii].checked === true) {
                            checkedValsCount++;
                        }
                    }
                    if (checkedValsCount == 0) {
                        inpChoiceElems[0].focus();
                        zf_ShowErrorMsg(zf_MandArray[i]);
                        return false;
                    }
                }
            }
        }

        return true;

    }

    function zf_ValidCheck(formId) {
        var isValid = true;
        for (var ind = 0; ind < zf_FieldArray.length; ind++) {
            var fieldObj = document.getElementById(formId)[zf_FieldArray[ind]];
            if (fieldObj) {
                if (fieldObj.nodeName != null) {
                    var checkType = fieldObj.getAttribute("checktype");
                    if (checkType == "c2") { // No I18N
                        if (!zf_ValidateNumber(fieldObj)) {
                            isValid = false;
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_FieldArray[ind]);
                            return false;
                        }
                    } else if (checkType == "c3") { // No I18N
                        if (!zf_ValidateCurrency(fieldObj) || !zf_ValidateDecimalLength(fieldObj, 10)) {
                            isValid = false;
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_FieldArray[ind]);
                            return false;
                        }
                    } else if (checkType == "c4") { // No I18N
                        if (!zf_ValidateDateFormat(fieldObj)) {
                            isValid = false;
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_FieldArray[ind]);
                            return false;
                        }
                    } else if (checkType == "c5") { // No I18N
                        if (!zf_ValidateEmailID(fieldObj)) {
                            isValid = false;
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_FieldArray[ind]);
                            return false;
                        }
                    } else if (checkType == "c6") { // No I18N
                        if (!zf_ValidateLiveUrl(fieldObj)) {
                            isValid = false;
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_FieldArray[ind]);
                            return false;
                        }
                    } else if (checkType == "c7") { // No I18N
                        if (!zf_ValidatePhone(fieldObj)) {
                            isValid = false;
                            fieldObj.focus();
                            zf_ShowErrorMsg(zf_FieldArray[ind]);
                            return false;
                        }
                    } else if (checkType == "c8") { // No I18N
                        zf_ValidateSignature(fieldObj);
                    }
                }
            }
        }
        $('.zf-errorMessage').css('display', 'none');
        return isValid;
    }

    function zf_ShowErrorMsg(uniqName) {
        var fldLinkName;
        for (var errInd = 0; errInd < zf_FieldArray.length; errInd++) {
            fldLinkName = zf_FieldArray[errInd].split('_')[0];
            $('#' + frmId).find('.zf-errorMessage')[errInd] && ($('#' + frmId).find('.zf-errorMessage')[errInd].style.display = 'none');
        }
        var linkName = uniqName.split('_')[0];
        $('#' + frmId + ' [name="' + uniqName + '"]').closest('.zf-tempContDiv').find('.zf-errorMessage')[0] && ($('#' + frmId + ' [name="' + uniqName + '"]').closest('.zf-tempContDiv').find('.zf-errorMessage')[0].style.display = 'block');
    }

    function zf_ValidateNumber(elem) {
        var validChars = "-0123456789";
        var numValue = elem.value.replace(/^\s+|\s+$/g, '');
        if (numValue != null && !numValue == "") {
            var strChar;
            var result = true;
            if (numValue.charAt(0) == "-" && numValue.length == 1) {
                return false;
            }
            for (var i = 0; i < numValue.length && result == true; i++) {
                strChar = numValue.charAt(i);
                if ((strChar == "-") && (i != 0)) {
                    return false;
                }
                if (validChars.indexOf(strChar) == -1) {
                    result = false;
                }
            }
            return result;
        } else {
            return true;
        }
    }

    function zf_ValidateDateFormat(inpElem) {
        var dateValue = inpElem.value.replace(/^\s+|\s+$/g, '');
        if (dateValue == "") {
            return true;
        } else {
            return (zf_DateRegex.test(dateValue));
        }
    }

    function zf_ValidateCurrency(elem) {
        var validChars = "0123456789.";
        var numValue = elem.value.replace(/^\s+|\s+$/g, '');
        if (numValue != null && !numValue == "") {
            var strChar;
            var result = true;
            for (var i = 0; i < numValue.length && result == true; i++) {
                strChar = numValue.charAt(i);
                if (validChars.indexOf(strChar) == -1) {
                    result = false;
                }
            }
            return result;
        } else {
            return true;
        }
    }

    function zf_ValidateDecimalLength(elem, decimalLen) {
        var numValue = elem.value;
        if (numValue.indexOf('.') >= 0) {
            var decimalLength = numValue.substring(numValue.indexOf('.') + 1).length;
            if (decimalLength > decimalLen) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    function zf_ValidateEmailID(elem) {
        var check = 0;
        var emailValue = elem.value;
        if (emailValue != null && !emailValue == "") {
            var emailArray = emailValue.split(",");
            for (var i = 0; i < emailArray.length; i++) {
                var emailExp = /^[\w]([\w\-.+'/]*)@([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,22}$/;
                if (!emailExp.test(emailArray[i].replace(/^\s+|\s+$/g, ''))) {
                    check = 1;
                }
            }
            if (check == 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    function zf_ValidateLiveUrl(elem) {
        var urlValue = elem.value;
        if (urlValue !== null && typeof(urlValue) !== "undefined") {
            urlValue = urlValue.replace(/^\s+|\s+$/g, '');
            if (urlValue !== "") {
                var urlregex = new RegExp("^(((((h|H)(t|T){2}(p|P)(s|S)?)|((f|F)(t|T)(p|P)))://((w|W){3}.)?)|((w|W){3}.))[a-zA-Z0-9-\\P{InBasicLatin}\\s]+(\\.[a-zA-Z0-9-:;\\?#_]+)+(/[\\x21-\\x7e\\P{InBasicLatin}\\s]*)*$");
                return (urlregex.test(urlValue));
            }
        }
        return true;
    }

    function zf_ValidatePhone(inpElem) {
        var phoneFormat = parseInt(inpElem.getAttribute("phoneFormat"));
        var fieldInpVal = inpElem.value.replace(/^\s+|\s+$/g, '');
        var toReturn = true;
        if (phoneFormat === 1) {
            var IRexp = /^[+]*[()0-9- ]+$/;
            if (fieldInpVal != "" && !IRexp.test(fieldInpVal)) {
                toReturn = false;
                return toReturn;
            }
            return toReturn;
        } else if (phoneFormat === 2) {
            var InpMaxlength = inpElem.getAttribute("maxlength");
            var USARexp = /^[0-9]+$/;
            if (fieldInpVal != "" && USARexp.test(fieldInpVal) && fieldInpVal.length == InpMaxlength) {
                toReturn = true;
            } else if (fieldInpVal == "") {
                toReturn = true;
            } else {
                toReturn = false;
            }
            return toReturn;
        }
    }

    function zf_ValidateSignature(objElem) {
        var linkName = objElem.getAttribute("compname");
        var canvasElem = document.getElementById("drawingCanvas-" + linkName);
        var isValidSign = zf_IsSignaturePresent(objElem, linkName, canvasElem);
        var hiddenSignInputElem = document.getElementById("hiddenSignInput-" + linkName);
        if (isValidSign) {
            hiddenSignInputElem.value = canvasElem.toDataURL();
        } else {
            hiddenSignInputElem.value = ""; // No I18N
        }
        return isValidSign;
    }

    function zf_MandatoryCheckSignature(objElem) {
        var linkName = objElem.getAttribute("compname");
        var canvasElem = document.getElementById("drawingCanvas-" + linkName);
        var isValid = zf_IsSignaturePresent(objElem, linkName, canvasElem);
        return isValid;
    }

    function zf_IsSignaturePresent(objElem, linkName, canvasElem) {
        var context = canvasElem.getContext('2d'); // No I18N
        var canvasWidth = canvasElem.width;
        var canvasHeight = canvasElem.height;
        var canvasData = context.getImageData(0, 0, canvasWidth, canvasHeight);
        var signLen = canvasData.data.length;
        var flag = false;
        for (var index = 0; index < signLen; index++) {
            if (!canvasData.data[index]) {
                flag = false;
            } else if (canvasData.data[index]) {
                flag = true;
                break;
            }
        }
        return flag;
    }

    function zf_FocusNext(elem, event) {
        if (event.keyCode == 9 || event.keyCode == 16) {
            return;
        }
        if (event.keyCode >= 37 && event.keyCode <= 40) {
            return;
        }
        var compname = elem.getAttribute("compname");
        var inpElemName = elem.getAttribute("name");
        if (inpElemName == compname + "_countrycode") {
            if (elem.value.length == 3) {
                document.getElementsByName(compname + "_first")[0].focus();
            }
        } else if (inpElemName == compname + "_first") {
            if (elem.value.length == 3) {
                document.getElementsByName(compname + "_second")[0].focus();
            }
        }
    }


    $('.form').on('submit', function(e, param1) {
        if(param1 !== 'captchaSubmit') {
            e.preventDefault();
            frmId = $(this).closest(".form").attr("id");

            if (this.id == 'formLetsTalk') {
                zf_MandArray = ["Name_First", "Name_Last", "PhoneNumber_countrycode"];
                zf_FieldArray = ["Name_First", "Name_Last", "Email", "PhoneNumber_countrycode", "MultiLine"];
            } else {
                zf_MandArray = ["Name_First", "Name_Last", "PhoneNumber_countrycode", "Dropdown", "Dropdown1", "Dropdown2"];
                zf_FieldArray = ["Name_First", "Name_Last", "PhoneNumber_countrycode", "Email", "Dropdown", "Dropdown1", "Dropdown2", "MultiLine"];
            }

            if (!zf_ValidateAndSubmit()) {
                e.preventDefault();
                return;
            }

            $form = $(e.target);
            grecaptcha.execute();
        }
    });
});
